import React from "react"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"
import PropTypes from "prop-types"
import LangChanger from "./LangChanger"
import ModernImg from "./ModernImg"

export default function Header() {
  const { t } = useTranslation()

  return (
    <>
      {/* <!-- topbar area start --> */}
      <div className="topbar-area topbar-area-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-1" style={{ paddingLeft: 10 }}>
              <div className="lang-select">
                <LangChanger />
              </div>
            </div>
            <div className="col-md-7 col-sm-10 align-self-center">
              <div className="topbar-content text-lg-right">
                <span>
                  <i className="fa fa-map-marker-alt" aria-hidden="true"></i>9
                  Temasek Boulevard #38-02 Suntec City Tower 2 ,
                </span>
                <span className="mr-0">Singapore 038989</span>
              </div>
            </div>
            <div className="col-md-4 align-self-center d-none d-lg-block">
              <div className="topbar-content topbar-contact text-lg-right">
                <span className="mr-0">
                  {t("call-us")}{" "}
                  <a href="tel:+6568461800" style={{ marginLeft: 10 }}>
                    +65 6846 1800
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- topbar area end --> */}

      {/* <!-- navbar start --> */}
      <div className="navbar-area navbar-area-2">
        <nav className="navbar navbar-expand-lg">
          <div className="container nav-container">
            <div className="navbar-inner">
              <div className="responsive-mobile-menu">
                <button
                  className="menu toggle-btn d-block d-lg-none"
                  data-target="#themefie_main_menu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-left"></span>
                  <span className="icon-right"></span>
                </button>
              </div>
              <div className="logo">
                <Link className="main-logo" to="/">
                  <ModernImg
                    src="/assets/img/logo.png"
                    alt="img"
                    style={{ width: 60 }}
                  />
                </Link>
              </div>
              <div className="collapse navbar-collapse" id="themefie_main_menu">
                <ul className="navbar-nav menu-open">
                  <li>
                    <Link to="/" activeClassName="active">
                      {t("home")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us/" activeClassName="active">
                      {t("about-us")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ich-philosophy/" activeClassName="active">
                      ICH {t("philosophy")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/csr/" partiallyActive activeClassName="active">
                      {t("csr")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/media/" activeClassName="active">
                      {t("media")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/newsletters/"
                      partiallyActive
                      activeClassName="active"
                    >
                      {t("newsletters")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/careers/" activeClassName="active">
                      {t("careers")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us/" activeClassName="active">
                      {t("contact-us")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* <!-- navbar end --> */}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
