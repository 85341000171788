import React from "react"

export default function ModernImg({ webp, src, ...rest }) {
  const webpSrc = webp || src.substr(0, src.lastIndexOf(".")) + ".webp"
  return (
    <picture>
      <source srcSet={webpSrc} type="image/webp" />
      <source srcSet={src} />
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img src={src} {...rest} />
    </picture>
  )
}
