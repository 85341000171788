import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"

export default function LangChanger() {
  const { languages, changeLanguage, language } = useI18next()

  function handleChange(e) {
    const lang = e.target.value
    changeLanguage(lang)
  }
  return (
    <select
      style={{ width: 70, textTransform: "uppercase" }}
      className="languages"
      defaultValue={language}
      name="langs"
      onChange={handleChange}
    >
      {languages.map(lang => {
        let emoji = "🇺🇸"
        if (lang === "ch") emoji = "🇨🇳"
        return (
          <option
            key={lang}
            value={lang}
            style={{ textTransform: "uppercase" }}
          >
            {`${emoji} ${lang}`}
          </option>
        )
      })}
    </select>
  )
}
