import React from "react"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"
import LangChanger from "./LangChanger"
import ModernImg from "./ModernImg"

export function Footer() {
  const { t } = useTranslation()
  return (
    <>
      {/* footer area start */}
      <footer
        className="footer-area footer-area-2"
        style={{ background: "#082e5b" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-3 col-md-6 col-sm-8">
              <div style={{ marginBottom: 20 }}>
                <Link to="/">
                  <ModernImg
                    src="/assets/img/logo_white.png"
                    alt="logo"
                    style={{ width: 100 }}
                  />
                </Link>
              </div>
              <LangChanger />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-4">
              <div className="footer-widget widget widget_link">
                <h4 className="widget-title">{t("quick-links")}</h4>
                <ul>
                  <li>
                    <Link to="/">{t("home")}</Link>
                  </li>
                  <li>
                    <Link to="/about-us/">{t("about-us")}</Link>
                  </li>
                  <li>
                    <Link to="/ich-philosophy/">
                      ICH {t("philosophy")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/csr/">{t("csr")}</Link>
                  </li>
                  {/* <li> */}
                  {/* <Link to="/portfolio/">{t("portfolio")}</Link> */}
                  {/* </li> */}
                  <li>
                    <Link to="/newsletters/">{t("newsletters")}</Link>
                  </li>
                  <li>
                    <Link to="/careers/">{t("careers")}</Link>
                  </li>
                  <li>
                    <Link to="/contact-us/">{t("contact-us")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-5">
              <div className="footer-widget widget widget_info">
                <h4 className="widget-title">{t("information")}</h4>
                <p>
                  <i className="fa fa-map-marker-alt" />
                  {t("location")} <br /> 9 Temasek Boulevard #38-02 Suntec City
                  Tower 2 <br />
                  Singapore 038989
                </p>
                <p className="phone">
                  <i className="fa fa-phone-alt" />
                  +65 6846 1800
                </p>
                <p className="email">
                  <i className="fa fa-envelope" />
                  <a href="mailto:contact@ich-group.com">
                    contact@ich-group.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-7"></div>
          </div>
        </div>
      </footer>
      {/* footer area end */}
      {/*Footer bottom*/}
      <div className="footer-bottom footer-bottom-2">
        <div className="container">
          <div className="copyright-area">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <p>
                  Copyright {new Date().getFullYear().toString()}, ICH Group.
                  All rights reserved.
                </p>
              </div>
              <div className="col-md-4 text-md-right"></div>
            </div>
          </div>
        </div>
      </div>
      {/*Footer bottom*/}
      {/* back to top area start */}
      <div className="back-to-top">
        <span className="back-top">
          <i className="fa fa-angle-up" />
        </span>
      </div>
    </>
  )
}
