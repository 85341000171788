/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import React, { useEffect } from "react"
import Header from "./header"
import { Footer } from "./footer"
import { Helmet } from "react-helmet"
import $ from "jquery"
import "../styles/ich-styles.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window === `undefined`) return

    $(document).on(
      "click",
      ".navbar-area .navbar-nav li.menu-item-has-children>a",
      function (e) {
        e.preventDefault()
      }
    )

    $(".navbar-area .menu").on("click", function () {
      $(this).toggleClass("open")
      $(".navbar-area .navbar-collapse").toggleClass("sopen")
    })

    var menutoggle = $(".menu-toggle")
    var mainmenu = $(".navbar-nav")

    menutoggle.on("click", function () {
      if (menutoggle.hasClass("is-active")) {
        mainmenu.removeClass("menu-open")
      } else {
        mainmenu.addClass("menu-open")
      }
    })

    $(document).on("click", ".back-to-top", function () {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        2000
      )
    })

    $(window).on("scroll", function () {
      var mainMenuTop = $(".navbar-area")
      if ($(window).scrollTop() >= 1) {
        mainMenuTop.addClass("navbar-area-fixed")
      } else {
        mainMenuTop.removeClass("navbar-area-fixed")
      }

      var ScrollTop = $(".back-to-top")
      if ($(window).scrollTop() > 1000) {
        ScrollTop.fadeIn(1000)
      } else {
        ScrollTop.fadeOut(1000)
      }
    })

    $(window).on("load", function () {
      var backtoTop = $(".back-to-top")
      backtoTop.fadeOut()
    })
  }, [])

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          href="assets/img/favicon.png"
          sizes="20x20"
          type="image/png"
        />

        <link rel="stylesheet" href="/assets/css/vendor.css" />
        <link rel="stylesheet" href="/assets/css/style.css" />
        <link rel="stylesheet" href="/assets/css/responsive.css" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
